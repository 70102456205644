import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
   this.sortable = Sortable.create(this.element, {
    onEnd: this.end.bind(this)
   }) 
  }


  
  end(event){
    let id = event.item.dataset.id
    let data = new FormData()
    data.append("new_position", event.newIndex + 1)
    data.append("widget_id", id)

    $.ajax({
      url: '/custom_widgets/:id/move_widget'.replace(":id",id) + '?widget_id=' + id + '&new_position=' + (event.newIndex + 1).toString() + '&old_position=' + (event.oldIndex + 1).toString(),
      processData: false,
      contentType: false,
      data: data
    })

  }

}