import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  end(event){
    console.log(event)
    let id = event.item.dataset.id
    let position_id = event.item.dataset.position_id
    console.log(id)
    let data = new FormData()
    data.append("new_vertical_position", event.newIndex)
    console.log(data)

    $.ajax({
      method: 'PATCH',
      data: data,
      processData: false,
      contentType: false,
      url: ':position_id/position_competencies/:id/move'.replace(":id",id).replace(":position_id",position_id)
      
    });
    
  }
}
