import { Controller } from 'stimulus';
export default class extends Controller {
    connect() {
        console.log("hello from mailFlow StimulusJS")
        $(this.element).on('switchChange.bootstrapSwitch', function() {
            const token = document.querySelector('meta[name="csrf-token"]').content
            const mail_flow_id = parseInt($(this).attr("id"))
            if ($(this).children().hasClass("bootstrap-switch-on")) {
                fetch('/mail_flows/' + mail_flow_id + '/activate_task_creation', {
                    method: 'PUT',
                    credentials: 'same-origin',
                    headers: {
                        'X-CSRF-Token': token,
                        'Content-type': 'application/json; charset=UTF-8' // Indicates the content
                    }
                })
            } else {
                fetch('/mail_flows/' + mail_flow_id + '/deactivate_task_creation', {
                    method: 'PUT',
                    credentials: 'same-origin',
                    headers: {
                        'X-CSRF-Token': token,
                        'Content-type': 'application/json; charset=UTF-8' // Indicates the content
                    }
                });
            }


        })
    }
}