import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
    $(this.element).on('change', function () {
      const token = document.querySelector('meta[name="csrf-token"]').content
      const email_draft_id =  parseInt($(this).attr("id"))
      if ($(this).children().is(':checked')){
        fetch('/email_drafts/' + email_draft_id + '/activate_auto_mail', {
          method: 'PUT',
          credentials: 'same-origin',
          headers: {
              'X-CSRF-Token': token,
              'Content-type': 'application/json; charset=UTF-8' // Indicates the content
          }
      })
      }
      else{
        fetch('/email_drafts/' + email_draft_id + '/deactivate_auto_mail', {
          method: 'PUT',
          credentials: 'same-origin',
          headers: {
              'X-CSRF-Token': token,
              'Content-type': 'application/json; charset=UTF-8' // Indicates the content
          }
      });
      }


    })
  }
}
