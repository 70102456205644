import { Controller } from 'stimulus';
export default class extends Controller {
    connect() {
        console.log("hello from workFlow StimulusJS")
        $(this.element).on('switchChange.bootstrapSwitch', function() {
            const token = document.querySelector('meta[name="csrf-token"]').content
            const work_flow_id = parseInt($(this).attr("id"))
            if ($(this).children().hasClass("bootstrap-switch-on")) {
                fetch('/work_flows/' + work_flow_id + '/activate_mail_automation', {
                    method: 'PUT',
                    credentials: 'same-origin',
                    headers: {
                        'X-CSRF-Token': token,
                        'Content-type': 'application/json; charset=UTF-8' // Indicates the content
                    }
                })
            } else {
                fetch('/work_flows/' + work_flow_id + '/deactivate_mail_automation', {
                    method: 'PUT',
                    credentials: 'same-origin',
                    headers: {
                        'X-CSRF-Token': token,
                        'Content-type': 'application/json; charset=UTF-8' // Indicates the content
                    }
                });
            }


        })
    }
}