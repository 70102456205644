import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  end(event){
    console.log(event)
    let id = event.item.dataset.id
    console.log(id)
    let data = new FormData()
    data.append("new_position", event.newIndex + 1)
    console.log(data)

    $.ajax({
      method: 'PATCH',
      data: data,
      processData: false,
      contentType: false,
      url: '/positions/:id/move'.replace(":id",id)
      
    });
    
  }
}
