import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
    });
    const saveElement = document.getElementById('view-preference-apply');
    saveElement.addEventListener('click', this.savePreferences.bind(this));

    this.element.addEventListener('change', this.handleCheckboxChange.bind(this));

  }

  end(event) {
    const id = event.item.dataset.id;
    const optItems = document.querySelectorAll('li.opt-item');
    const data = {
      preferences: {},
    };
  
    optItems.forEach((item, index) => {
      const checkbox = $(".blue-checkbox[id='" + item.dataset.itemKey + "']");
      data.preferences[item.dataset.itemKey] = {
        status: checkbox.prop('checked'),
        horizontal_alignment: index + 1,
      };
    });
  
    this.element.setAttribute('data-my-controller-data', JSON.stringify(data));
  }
  
  handleCheckboxChange(event) {
    const checkbox = event.target;
    if (checkbox.classList.contains('list-option-cb')) {
      this.updateList();
    }
  }

  updateList() {
    const optItems = document.querySelectorAll('li.opt-item');
    const data = {
      preferences: {},
    };

    optItems.forEach((item, index) => {
      const checkbox = $(".blue-checkbox[id='" + item.dataset.itemKey + "']");
      data.preferences[item.dataset.itemKey] = {
        status: checkbox.prop('checked'),
        horizontal_alignment: index + 1,
      };
    });

    this.element.setAttribute('data-my-controller-data', JSON.stringify(data));
  }

  savePreferences() {
    const id = (this.element.dataset.id).split("-")[0];
    const name = (this.element.dataset.id).split("-")[1];
    const dataString = this.element.getAttribute('data-my-controller-data');
    const data = JSON.parse(dataString);

    $.ajax({
      url: '/user_preferences/' + id + '/update_view_option' + '?preferences_name=' + name ,
      method: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: function (response) {
      
      },
      error: function (error) {
        
      }
    });
  }
}
